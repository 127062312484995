import { CanvasCardConfig, CanvasElement } from "interfaces/canvas";
import React, { useContext, useRef } from "react";
import { defaultCanvasSize } from "utils/constants";
export type CanvasProviderValues = {
  projectName: string;
  selectedCanvasMenu: string;
  isExpandedSidebar: boolean;
  canvasRef: React.MutableRefObject<CustomFabricCanvas | null> | any;
  canvaSetup: { config: CanvasCardConfig; elements: CanvasElement[] }[];
  selectedCanvaPage: number;
  storeProjectName: (value: string) => void;
  storeSelectedCanvasMenu: (value: string) => void;
  storeExpandedSidebar: (value: boolean) => void;
  storeCanvasSetup: (
    value: { config: CanvasCardConfig; elements: CanvasElement[] }[]
  ) => void;
  storeSelectedCanvaPage: (value: number) => void;
};
const initState = {
  projectName: "",
  selectedCanvasMenu: "",
  selectedCanvaPage: 1,
  isExpandedSidebar: false,
  canvasRef: null,
  canvaSetup: [
    {
      config: {
        bgColor: "",
        width: 0,
        height: 0
      },
      elements: [
        {
          id: "",
          type: "",
          content: "",
          config: {
            fontSize: undefined,
            color: undefined,
            xAxis: "",
            yAxis: "",
            height: 0,
            width: 0,
            zIndex: 1,
            flipH: false,
            flipV: false
          }
        }
      ]
    }
  ],
  storeProjectName: (value: string) => void value,
  storeSelectedCanvasMenu: (value: string) => void value,
  storeExpandedSidebar: (value: boolean) => void value,
  storeCanvasSetup: (
    value: { config: CanvasCardConfig; elements: CanvasElement[] }[]
  ) => void value,
  storeSelectedCanvaPage: (value: number) => void value
};
const CanvasContext = React.createContext<CanvasProviderValues>(initState);
export const useCanvasRef = () => {
  return useContext(CanvasContext);
};
export const CanvasProvider = CanvasContext.Provider;
type Props = {
  children?: React.ReactNode;
};
interface CanvasAction {
  type: "add" | "remove" | "modify";
  object: fabric.Object;
  before: any;
  after: any;
}
interface CustomFabricCanvas extends fabric.Canvas {
  historyUndo: CanvasAction[];
  historyRedo: CanvasAction[];
  isUndoing: boolean;
  isRedoing: boolean;
  upperCanvasEl?: HTMLCanvasElement;
}
export const CanvasProviderContainer: React.FC<Props> = ({ children }) => {
  const canvasRef = useRef<CustomFabricCanvas | null>(null);

  const [isExpandedSidebar, setIsExpandedSidebar] =
    React.useState<boolean>(false);

  const [canvaSetup, setCanvaSetup] = React.useState<
    { config: CanvasCardConfig; elements: CanvasElement[] }[]
  >([
    {
      config: {
        bgColor: defaultCanvasSize.color,
        width: defaultCanvasSize.width,
        height: defaultCanvasSize.height
      },
      elements: []
    }
  ]);

  const [selectedCanvaPage, setSelectedCanvaPage] = React.useState<number>(1);
  const [projectName, setProjectName] =
    React.useState<string>("Untiled project");

  const [selectedCanvasMenu, setSelectedCanvasMenu] =
    React.useState<string>("");

  const storeCanvasSetup = (
    value: { config: CanvasCardConfig; elements: CanvasElement[] }[]
  ) => {
    setCanvaSetup(value);
  };

  const storeProjectName = (value: string) => {
    setProjectName(value);
  };

  const storeExpandedSidebar = (value: boolean) => {
    setIsExpandedSidebar(value);
  };

  const storeSelectedCanvasMenu = (value: string) => {
    setSelectedCanvasMenu(value);
  };

  const storeSelectedCanvaPage = (value: number) => {
    setSelectedCanvaPage(value);
  };
  return (
    <CanvasProvider
      value={{
        projectName,
        canvaSetup,
        canvasRef,
        isExpandedSidebar,
        selectedCanvasMenu,
        selectedCanvaPage,
        storeProjectName,
        storeExpandedSidebar,
        storeSelectedCanvasMenu,
        storeCanvasSetup,
        storeSelectedCanvaPage
      }}
    >
      {children}
    </CanvasProvider>
  );
};
export default CanvasContext;
